/* additional js here */
var template_path = $('html').data('path');
var child_path = template_path.replace('webwerk', 'webwerk-child');

// presentation-nav
function has_cookie(name) {
  if (document.cookie.indexOf(name + '=true') >= 0) {
    return true;
  }
  return false;
}

if (has_cookie('contrast')) {
  $('html').addClass('contrast');
}

$(document).ready(function () {
  // Open Subnav
  var plusIcon = '<svg role="img" class="primary-nav__icon" aria-hidden="true" focusable="false"><use xlink:href="' + template_path + '/img/icons.svg#plus"></use></svg>';
  var minusIcon = `<svg role="img" class="primary-nav__icon" aria-hidden="true" focusable="false"><use xlink:href="${template_path}/img/icons.svg#minus"></use></svg>`;
  var path = window.location.pathname;
  var currentPage = $(`a[href$="${path}"]`);
  var parents = currentPage.parents('.primary-nav__item--has-sub, .primary-subnav__item--has-sub');
  parents.addClass('open').children('div, ul').css('display', 'block');
  //parents.children('a').find('svg').remove().end().append(minusIcon);

  //  console.log($('body').hasClass('home'));
  //  console.log('start');

  if (!$('body').hasClass('home')) {
    parents.children('a').find('svg').remove().end().append(minusIcon);
  }

  if ($('body').hasClass('home')) {
    $('.primary-nav__item, .primary-nav__item--has-sub').removeClass('open').children('div, ul').css('display', 'none');
    $('.primary-nav__item:first-of-type').addClass('open').children('div, ul').css('display', 'block');
    $('.primary-nav__item:first-of-type').find('svg').remove();
    $('.primary-nav__item:first-of-type').children('a').append(minusIcon);
  }

  if ($('body').hasClass('search-page')) {
    $('.primary-nav__item, .primary-nav__item--has-sub').removeClass('open').children('div, ul').css('display', 'none');
    $('.primary-nav__item--has-sub, .primary-subnav__item--has-sub').removeClass('open').children('a').append(plusIcon);
  }

});

$(document).ready(function () {
  //add icon
  let NavItem = $('.presentation-nav__list');
  NavItem.before('<svg role="img" class="presentation-nav__icon" aria-hidden="true" focusable="false"><use xlink:href="' + child_path + '/img/icons.svg#contrast"></use></svg><p class="presentation-nav__info">Darstellung:</p>');
  //add text to li
  $('.presentation-nav__item').each(function () {
    let className = $(this).find('a').text().toLowerCase();
    $(this).find('a').addClass('presentation-nav__link--' + className);
  });
  //add class to html
  let linkItem = $('.presentation-nav__item a');

  linkItem.click(function (e) {
    e.preventDefault();
    if ($(this).hasClass('presentation-nav__link--standard')) {
      document.cookie = "contrast=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict";
      $('html').removeClass('contrast').addClass('standard');
    } else if ($(this).hasClass('presentation-nav__link--kontrast')) {
      document.cookie = "contrast=true; path=/; SameSite=Strict";
      $('html').removeClass('standard').addClass('contrast');
    }
  });


  // Login
  let loginInput = $('#user_login');
  let headerLogin = $('.header-login');

  $(document).ready(function () {
    $('.login-remember').remove();
  })

  $('.meta-nav__item--intern button, .header-login__btn').click(function (e) {
    e.preventDefault();
    if ($(this).is('.meta-nav__item--intern button')) {
      if (headerLogin.css('display') === 'none') {
        headerLogin.css('display', 'block');
      } else {
        headerLogin.css('display', 'none');
      }
      loginInput.focus();
    } else if ($(this).is('.header-login__btn')) {
      $('.meta-nav__item--intern button').focus();
    }
  });

});

// Blog Rows add class if no img
$(document).ready(function () {
  $('.blog-post__link').has('figure').addClass('blog-post__link--img');
});

// Login Field Error Message
$(document).ready(function () {
  $('.header-login__btn').click(function (e) {
    e.preventDefault();

    $('.header-login__error').remove();

    // var inputEmail = $('.header-login__input--user').val();
    // var inputPwd = $('.header-login__input--pwd').val();

    // if (inputEmail === '' && inputPwd === '') {
    //   // Input is empty, add error message
    //   $('#username').before('<p class="header-login__error header-login__error--user">Sie haben keine gültige E-Mail-Adresse angegeben</p>').focus();
    //   $('#password').before('<p class="header-login__error header-login__error--pwd">Sie haben kein gültiges Passwort angegeben</p>');
    // }

    // else if (inputPwd === '') {
    //   $('#password').before('<p class="header-login__error header-login__error--pwd">Sie haben kein gültiges Passwort angegeben</p>').focus();
    // }

    // else if (inputEmail === '') {
    //   $('#username').before('<p class="header-login__error header-login__error--user">Sie haben keine gültige E-Mail-Adresse angegeben</p>').focus();
    // }

    // else {
    //   // Input is not empty, submit the form
    //   $('form').submit();
    // }
  });

  $('#username, #password').click(function () {
    $('.header-login__error').remove();
  });
});

// empty Search
$(document).ready(function () {
  var href = window.location.href;
  var desiredBaseUrl = href.split("?")[0];
  var desiredUrl = desiredBaseUrl + "?s=";
  // console.log(desiredUrl);

  if (href === desiredUrl) {
    $('.search-result__pagination').remove();
  }
});

// Archiv Breadcrumb löschen
// $(document).ready(function () {
//   var currentURL = window.location.href;

//   if (currentURL.indexOf('/category/') !== -1) {
//     $('.breadcrumb-nav li:nth-child(2)').remove();
//   }
// })

// Inerner Bereich lösche Zurück Button
// $(document).ready(function () {
//   var currentURL = window.location.href;

//   if (currentURL.indexOf('/intern/') !== -1) {
//     $('.single-post__btn').remove();
//   }
// })





